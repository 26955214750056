.toggle-dropdown-enter-from,
.toggle-dropdown-leave-to {
  opacity: 0;
  transform: translateY(5%);
}

.toggle-dropdown-enter-to,
.toggle-dropdown-leave-from {
  opacity: 1;
  transform: translateX(0);
}