.header {
  display: flex;
  padding: em(0 12, $fz);
  background-color: var(--header);
  
  @media screen and (max-width: $macBook_pro_13inch) {
    padding-inline-end: 0;
  }
  
  &__balance {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }
  
  &__time {
    min-width: em(120, $fz);
    margin-inline-start: em(20, $fz);
  }
  
  &__location {
    @include media-tablet {
      display: none;
    }
    padding: em(0 16, $fz);
    color: var(--header_text);
    
    :deep(.location__timezone) {
      opacity: .5;
      color: var(--header_text_64);
    }
  }
}

.header-balance {
  display: flex;
  align-items: center;
  
  &__prepaid {
    margin-inline-start: em(16, $fz);
    
    padding-inline-start: em(16, $fz);
    border-inline-start: 1px solid var(--phantom_20);
  }
  
  &__inner {
    display: flex;
    align-items: flex-end;
  }
}

.header-balance-current {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__value {
    $fz: 23;
    color: var(--header_text);
    font-size: em($fz, $fz_base);
    font-weight: 300;
    line-height: em(26, $fz);
  }
  
  &__subtitle {
    $fz: 13;
    opacity: 0.48;
    color: var(--header_text_64);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(16, $fz);
  }
}

.header-balance-prepaid {
  display: flex;
  flex-direction: column;
  
  &__text {
    display: flex;
    align-items: center;
    column-gap: em(5, $fz);
  }
  
  &__info {
    &--is-normal {
      color: var(--success_light);
    }
    
    &--is-caution {
      color: var(--caution_light);
    }
    
    &--is-alert {
      color: var(--alert_light);
    }
  }
  
  &__sum {
    $fz: 16;
    color: var(--header_text);
    font-size: em($fz, $fz_base);
    font-weight: 300;
    line-height: em(18, $fz);
  }
  
  &__subtitle {
    $fz: 13;
    opacity: 0.48;
    color: var(--header_text_64);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(16, $fz);
  }
  
  &__limit {
    height: em(4, $fz);
    margin-top: em(2, $fz);
    margin-bottom: em(6, $fz);
    background-color: var(--header_text);
  }
}

.header-time {
  position: relative;

  display: flex;
  
  &__current {
    $fz: 25;
    flex-grow: 1;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: em(0 15, $fz);
    color: var(--phantom);
    font-family: $font_Roboto_Mono;
    font-size: em($fz, $fz_base);
    font-weight: 300;
    
    
    &--is-disabled {
      background-color: var(--header_text_40);
    }
    
    &--is-active {
      background-color: var(--success_light);
    }
    
    &--is-warning {
      background-color: var(--caution_light);
    }
    
    &--is-exceed {
      background-color: var(--alert_light);
    }
  }
  
  &__control {
    position: absolute;
    top: 100%;
    right: em(-40, $fz);
    z-index: 3;
    min-width: em(320, $fz);
    max-width: em(360, $fz);
  }
}