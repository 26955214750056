/* custom-reset.css | 27.08.2018 | https://yurch-html.github.io/dist/custom-reset.html */
//stylelint-disable

button,
select {
  text-align: left;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;

  box-sizing: border-box;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    display: flex;
    flex-direction: column;
  }
}

body {
  height: 100%;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-decoration-skip: objects;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

//noinspection CssInvalidPseudoSelector
::input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s;
}

//noinspection CssInvalidPseudoSelector
::-moz-input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s;
}

::-webkit-file-upload-button {
  appearance: none;
  font: inherit;
}

:focus {
  outline: none;
}

p,
dd,
dl,
figure,
blockquote {
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

ul,
ol {
  margin: 0;

  padding: 0;
  list-style-type: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

audio,
video {
  display: block;
}

img {
  max-width: 100%;

  display: block;
  border: none;
}

iframe {
  border: none;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  font-size: inherit;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

abbr {
  border: none;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

i,
em {
  font-style: inherit;
}

dfn {
  font-style: inherit;
}

mark {
  background-color: transparent;
  color: inherit;
}

small {
  font-size: inherit;
}

sub,
sup {
  position: relative;

  vertical-align: baseline;
  font-size: inherit;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

form {
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;

  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  box-shadow: none;
  font: inherit;
  letter-spacing: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  appearance: none;
}

textarea {
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border: none;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: none;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline: none;
}

[type='search']::-webkit-search-decoration {
  appearance: none;
}

fieldset {
  margin: 0;

  padding: 0;
  border: none;
}

legend {
  display: block;
  padding: 0;
  white-space: normal;
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

::placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s;
}

:disabled,
.disabled {
  cursor: not-allowed;
}

//noinspection CssInvalidPseudoSelector
:input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s;
}

//noinspection CssInvalidPseudoSelector
:focus::input-placeholder {
  opacity: 0;
}

//noinspection CssInvalidPseudoSelector
:focus::-moz-input-placeholder {
  opacity: 0;
}

:focus::placeholder {
  opacity: 0;
}

svg {
  width: 100%;
  height: 100%;

  display: block;
  fill: currentColor;
}

[hidden] {
  display: none;
}

::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  
  margin: 0;
}

// Firefox
input[type="number"] {
  appearance: textfield;
}
