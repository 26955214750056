.progressbar {
  width: 100%;
  max-width: em(250, $fz);
  height: em(8, $fz);

  display: flex;
  border-radius: em(8, $fz);
  background: var(--divider);

  &__value {
    height: 100%;

    border-radius: inherit;
    background-image: repeating-linear-gradient(-35deg, var(--normal) 0 14px, #5B8EBB 10px 23px);
  }
}