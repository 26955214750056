@keyframes EmptyStateFlicker {
  from { opacity: 0.6; }
}

.empty-state {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--brand_64);
  font-family: $font_Roboto;
  
  [dir="rtl"] & {
    font-family: $font_Cairo;
    line-height: 100%;
  }

  &__icon {
    color: var(--brand_40);
  }

  &__icon-flicker {
    animation: EmptyStateFlicker 1s infinite alternate;
  }

  &__progressbar {
    margin-top: em(24, $fz);
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    $fz: 20;
    margin-top: em(24, $fz);
    color: var(--brand_64);
    font-size: em($fz, $fz_base);
    font-weight: 700;
  }

  &__subtitle {
    $fz: 14;
    margin-top: em(12, $fz);
    color: var(--brand_64);
    font-size: em($fz, $fz_base);
    line-height: em(22, $fz);
  }

  &__button {
    min-width: em(256, $fz);
    margin-top: em(24, $fz);
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }

  &--in-coupon {
    .empty-state {
      &__icon {
        width: em(140, $fz);
        height: em(140, $fz);

        opacity: 0.1;
        color: var(--brand_light);
      }

      &__title {
        margin-top: em(55, $fz);
      }
    }
  }
}