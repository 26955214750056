.location {
  display: flex;
  align-items: center;
  column-gap: em(8, $fz);
  
  &__info {
    display: grid;
  }
  
  &__name,
  &__date {
    $fz: 14;
    font-size: em($fz, $fz_base);
    font-weight: 500;
  }
  
  &__date {
    display: flex;
    align-items: center;
    column-gap: em(4, $fz);
    text-transform: uppercase;
  }
  
  &__time {
    font-weight: 500;
  }
  
  &--is-desktop {
    .location {
      &__info {
        gap: em(4, $fz);
      }
      
      &__name,
      &__date {
        $fz: 14;
        font-size: em($fz, $fz_base);
        font-weight: 500;
        line-height: 100%;
      }
    }
  }
  
  &--is-mobile {
    .location {
      &__name {
        $fz: 13;
        font-size: em($fz, $fz_base);
        font-weight: 500;
        line-height: em(16, $fz);
      }
      
      &__date {
        $fz: 12;
        font-size: em($fz, $fz_base);
        line-height: em(16, $fz);
      }
    }
  }
}