.workspace {
  height: 100%;

  display: flex;
  flex-direction: column;

  &__wrapper {
    @include media-tablet {
      padding: em(20 20 0, $fz);
    }
    
    flex: 1 1 auto;
    max-height: calc(100vh - em(64, $fz));

    display: flex;
    padding: em(28 28 0, $fz);
    background-color: var(--bg);
  }

  &__inner {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
  }
  
  &__header {
    height: var(--header-height_desktop);
  }

  &__betting {
    height: 100%;
  }

  &__content {
    position: relative;

    overflow: hidden;
  }

  &__events {
    max-width: em(430, $fz);
  }

  &__row {
    height: 100%;

    display: flex;
  }

  &__asides {
    min-height: 0;
    flex-grow: 1;

    display: flex;
    
    &--is-betting {
      .workspace__aside {
        flex: 1 0 50%;
      }
      
      .workspace__payments {
        max-width: 100%;
      }
    }
  }

  &__aside {
    position: relative;
    z-index: 1;
    min-height: 0;
    flex-basis: 50%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    background-color: var(--phantom);
  
    & + &:not(&--with-gap) {
      margin-inline-start: 1px;
    }

    &--events {
      max-width: em(568, $fz);
      
      @media screen and (max-width: $old_laptops) {
        max-width: em(430, $fz);
      }
    }
    
    &--is-event,
    &--markets {
      max-width: em(770, $fz);
      
      @media screen and (max-width: $old_laptops) {
        max-width: em(423, $fz);
      }
    }

    &--is-event {
      box-shadow: em(0 4 20, $fz) var(--shadow);
    }
    
    &--is-not-rubber {
      flex: 0 1 47%;
    }

    &--coupon {
      flex: 0 0 auto;
      align-self: flex-start;
      width: em(386, $fz);
      max-height: calc(100% - #{em(24, $fz)});
      margin-bottom: em(24, $fz);
      
      @media (max-width: 1450px) {
        width: em(316, $fz);
      }
    }

    &--size-s {
      max-width: em(315, $fz);
      flex-basis: 42%;
      flex-grow: 0;
    }

    &--with-gradient {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        content: '';
        background: var(--gradient_top);
      }
    }

    &--is-registration {
      padding: em(20 40, $fz);
    }
    
    &--is-inactive {
      position: relative;
      
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;

        content: '';
        background-color: var(--phantom_40);
      }
    }
    
    &--is-active {
      box-shadow: em(0 4 20, $fz) var(--shadow);
    }
    
    &--with-gap {
      margin-inline-start: em(8, $fz);
    }
  }

  &__close {
    position: absolute;
    top: em(8, $fz);
    right: em(8, $fz);
    color: var(--brand_40);
  }
  
  &__payments {
    @include media-tablet {
      max-width: 100%;
    }
    
    position: relative;
    max-width: 72.27%;
    height: 100%;
    flex-grow: 1;
  }
  
  &__payout-search {
    &--is-empty {
      min-width: em(526, $fz);
      margin: auto;
    }
    
    &:not(&--is-empty) {
      max-width: em(315, $fz);
    }
  }
}

.aside {
  &__title {
    $fz: 19;
    margin-bottom: em(36, $fz);
    color: var(--brand);
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    line-height: em(24, $fz);
  }
  
  &__content {
    flex-grow: 1;

    padding: em(32 40, $fz);
  }
}

//events
.workspace-betting {
  display: flex;
  gap: em(28, $fz);
}

.workspace-events {
  color: var(--brand);
  font-family: $font_Roboto_Condensed;

  &__header {
    padding: em(16 18, $fz);
    border-bottom: 1px solid var(--divider);
  }

  &__search {
    margin-bottom: em(16, $fz);
  }
  
  &__list {
    max-height: 100%;
    flex-grow: 1;
  }
}

.workspace-events-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workspace-leagues {
  &__item {
    position: relative;
    
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: em(4, $fz);
      height: 100%;

      opacity: 0;

      content: '';
      background: var(--brand);
      transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
    
    &--is-open {
      &::before {
        opacity: 1;
      }
      
      .workspace-leagues__league {
        background-color: var(--normal_hover);
      }

      .workspace-league__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__league {
    min-height: em(56, $fz);
  }
  
  &__events {
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
  }
  
  &__empty {
    height: 100%;
  }
}

.events-enter-from,
.events-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.events-enter-to,
.events-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.workspace-league {
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: em(8, $fz);

  padding: em(0 20, $fz);
  transition: background-color 0.2s ease-in-out;

  &__inner {
    flex-grow: 1;

    display: flex;
    align-items: center;
  }

  &__sport {
    width: em(20, $fz);
    height: em(20, $fz);
    flex-shrink: 0;
    color: var(--brand);
  }

  &__country {
    width: em(28, $fz);
    height: em(20, $fz);
    margin-inline-end: em(8, $fz);
    margin-inline-start: em(12, $fz);

    flex-shrink: 0;
  }

  &__title {
    $fz: 15;
    flex-grow: 1;
    color: var(--brand);
    font-size: em($fz, $fz_base);
  }

  &__event-count {
    $fz: 15;
    color: var(--brand_40);
    font-size: em($fz, $fz_base);
    margin-inline-start: em(8, $fz);
  }

  &__arrow {
    transition: transform 0.2s ease-in-out;
  }
}

.workspace-league-toggle {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: em(20, $fz);
    height: em(20, $fz);
    color: var(--brand_40);
  }
}

.workspace-events-list {
  &__event {
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    
    &:hover {
      background-color: var(--normal_hover);
    }
    
    &:active,
    &--is-selected {
      background-color: var(--normal_selected);
    }
  }
  
  &--leagues {
    .workspace-event {
      padding: em(8 18 8 15, $fz);
    }
  }
}

.workspace-event {
  //cursor: pointer;
  //padding: em(20 32, $fz);

  &--size-medium {
    .workspace-event {
      &__main {
        margin: em(6 0, $fz);
      }

      &__teams {
        flex-grow: 1;
      }

      &__header {
        gap: em(10, $fz);
      }

      &__id {
        $fz: 12;
        min-width: em(36, $fz);
        font-size: em($fz, $fz_base);
      }
      
      &__sub-games {
        .select__label {
          $fz: 12;
          font-size: em($fz, $fz_base);
          line-height: em(16, $fz);
        }
      }
    }

    .workspace-event-team {
      gap: em(6, $fz);
      
      &__name,
      &__score {
        $fz: 15;
        font-size: em($fz, $fz_base);
        font-weight: 700;
      }
    }

    .workspace-event-info {
      &__text {
        $fz: 12;
        margin-inline-start: em(5, $fz);
        font-size: em($fz, $fz_base);
      }
    }
  }

  &--size-big {
    .workspace-event {
      &__icon {
        position: absolute;
        top: em(23, $fz);
        right: em(23, $fz);
        z-index: 0;

        opacity: 0.03;
      }

      &__header {
        gap: em(15, $fz);

        margin-top: em(15, $fz);
      }

      &__footer {
        margin-top: em(20, $fz);
      }

      &__id {
        $fz: 14;
        padding: em(2 7, $fz);
        border-radius: em(15, $fz);
        background-color: var(--brand);
        color: var(--phantom);
        font-size: em($fz, $fz_base);
      }
      
      &__team {
        min-height: em(39, $fz);
      }
      
      &__sub-games {
        .select__label {
          $fz: 14;
          font-size: em($fz, $fz_base);
          line-height: em(16, $fz);
        }
      }
    }

    .workspace-event-team {
      gap: em(6, $fz);
      
      &__name,
      &__score {
        $fz: 27;
        font-size: em($fz, $fz_base);
      }

      &__score {
        margin-inline-start: em(30, $fz);
      }
    }

    .workspace-event-info {
      &__icon {
        width: em(24, $fz);
        height: em(24, $fz);
      }

      &__text {
        $fz: 14;
        margin-inline-start: em(5, $fz);
        font-size: em($fz, $fz_base);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__main {
    display: flex;
    align-items: center;
    gap: em(5, $fz);
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: em(5, $fz);
  }

  &__id {
    text-align: center;
    font-weight: 700;
  }

  &__sub-games {
    .dropdown {
      left: em(-40, $fz) !important;
      min-width: em(218, $fz) !important;
    }
  }
}

.workspace-event-info {
  display: flex;
  align-items: center;
  
  &--is-live {
    color: var(--success);
  }
}

.workspace-event-team {
  display: flex;
  align-items: center;

  &__logo {
    &--is-default {
      color: var(--brand_40);
    }
  }

  &__name {
    flex-grow: 1;
  }
}

.workspace-markets {
  border-radius: em(0 4 4 0, $fz);
  color: var(--brand);
  font-family: $font_Roboto_Condensed;

  &__event {
    position: relative;

    padding: em(26 32 9, $fz);
    border-bottom: 1px solid var(--divider);
    background: var(--gradient_full);
  }

  &__close {
    position: absolute;
    top: em(8, $fz);
    right: em(8, $fz);
    z-index: 1;
    min-height: auto;

    padding: 0;
    color: var(--brand_40);
    
    &:hover {
      color: var(--brand);
    }
    
    &:active {
      color: var(--alert);
    }
  }

  &__filter {
    margin-top: em(18, $fz);
  }

  &__bets {
    padding: em(16 32, $fz);
  }

  &__header {
    padding: em(20 32 18, $fz);
    border-bottom: 1px solid var(--divider);
  }

  &__content {
    max-height: calc(100% - em(71, $fz));
  }
  
  &__empty {
    flex-grow: 1;
  }
}

.workspace-content {
  @include media-tablet {
    padding: 0;
  }
  
  min-height: 0;
  flex-grow: 1;
  
  //display: grid;
  //grid-template-rows: auto auto 1fr;
  display: flex;
  flex-direction: column;

  padding: em(20 24 0, $fz);
  border-radius: em(4, $fz);
  background-color: var(--phantom);
  
  &__title {
    flex-grow: 1;
  }

  &__header {
    @include media-tablet {
      margin-bottom: 0;

      padding: em(16 20 8, $fz);
    }
    min-height: em(40, $fz);
    //margin-bottom: em(12, $fz);

    display: flex;
    align-items: flex-start;
  }

  &__pagination {
    margin-top: em(24, $fz);
    margin-bottom: em(24, $fz);
  }

  &__maximize {
    color: var(--brand_40);
  }
  
  &__filter-chips {
    margin-bottom: em(12, $fz);
  }
  
  &__table {
    min-height: 0;
    flex-grow: 1;
  }
  
  &__empty {
    align-self: center;
    justify-self: center;
    grid-row: 1/4;
    max-width: 312px;

    display: flex;
  }
  
  &__filter-chips {
    margin-top: em(4, $fz);
  }
}

.workspace-content-title {
  display: flex;
  align-items: center;
  gap: em(8, $fz);
  
  &__label {
    $fz: 19;
    color: var(--brand);
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    line-height: em(24, $fz);
  }
  
  &__icon {
    color: var(--brand);
  }
}

.workspace-content-header__actions {}

.workspace-content-header-actions {
  display: flex;
  align-items: center;
  gap: em(12, $fz);
  transition: opacity .2s ease-in-out;
  
  &__label {
    $fz: 12;
    color: var(--brand);
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 500;
  }
  
  &__button {
    width: em(32, $fz);
    height: em(32, $fz);

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--btn_normal_default);
    border-radius: em(4, $fz);
    color: var(--btn_normal_default);
    transition: background-color .2s ease-in-out;
    
    &:hover {
      background-color: var(--normal_hover);
    }
    
    &:active {
      background-color: var(--normal_selected);
    }
  }
}

.toggle-table-actions-enter-from,
.toggle-table-actions-leave-to {
  opacity: 0;
}

.toggle-table-actions-enter-to,
.toggle-table-actions-leave-from {
  opacity: 1;
}
