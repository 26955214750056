.workspace-session-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__notice {
    $fz: 12;
    max-width: 100%;
    margin-top: em(4, $fz);
    color: var(--unactive);
    text-align: center;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
  }
}

.session-control {
  z-index: 2;

  padding: em(16 18 24, $fz);
  border-radius: em(4, $fz);
  background-color: var(--phantom);
  box-shadow: em(0 4 20 0, $fz) var(--shadow);
  transition: opacity .2s ease-in-out, transform .2s ease-in-out, visibility .2s ease-in-out;
  will-change: transform, opacity;
  filter: drop-shadow(#{em(0 4 4, $fz)} var(--shadow));
  
  &::before {
    position: absolute;
    top: calc(0em - #{em(4, $fz)});
    right: em(92, $fz);
    width: em(14, $fz);
    height: em(14, $fz);

    content: '';
    
    border-radius: em(3, $fz);
    background-color: var(--phantom);
    transform: rotate(45deg);
  }
  
  &__title {
    $fz: 24;
    color: var(--brand);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(24, $fz);
  }
  
  &__content {
    display: flex;
    align-items: flex-start;
    gap: em(12, $fz);
  }
  
  &__button {
    width: 100%;
    margin-top: em(24, $fz);
    
    :deep(.button__label) {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }
  }
  
  &__footer {
    margin-top: em(24, $fz);
  }
  
  &__location {
    margin-top: em(20, $fz);
    margin-bottom: em(16, $fz);
    
    padding-bottom: em(16, $fz);
    border-bottom: 1px solid var(--divider);
    
    :deep(.location__name) {
      $fz: 13;
      color: var(--brand);
      font-size: em($fz, $fz_base);
    }
    
    :deep(.location__date) {
      $fz: 12;
      color: var(--system_additional);
      text-transform: uppercase;
      font-size: em($fz, $fz_base);
      font-weight: 400;
    }
    
    :deep(.location__time) {
      $fz: 12;
      font-weight: 400;
      line-height: em(14, $fz);
    }
    
    :deep(.location__timezone) {
      color: var(--unactive);
    }
    
    :deep(.location__icon) {
      color: var(--brand_40);
    }
  }
  
  &__points {
    min-width: 0;
  }
}

.session-control-points {
  flex-grow: 1;
  
  display: flex;
  flex-direction: column;
  gap: em(20, $fz);
}

.session-control-info {
  display: flex;
  align-items: flex-start;
  gap: em(12, $fz);
  
  &__icon {
    color: var(--brand_40);
  }
  
  &__item {
    width: max-content;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  &__title {
    $fz: 13;
    color: var(--brand);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(16, $fz);
  }
  
  &__bottom {
    display: flex;
    align-items: center;
    column-gap: em(5, $fz);
  }
  
  &__subtitle {
    display: flex;
    align-items: center;
    gap: em(4, $fz);
  }
  
  &__label {
    $fz: 12;
    color: var(--system_additional);
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
  }
  
  &__notice {
    color: var(--brand_40);
  }
  
  &__notice {
    .icon__svg {
      color: var(--brand_40);
    }
  }
}

.session-control-footer {
  &__text {
    $fz: 14;
    padding: em(5 24, $fz);
    color: var(--unactive);
    text-align: center;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
  }
}

.session-control-enter-from,
.session-control-leave-to {
  will-change: transform, opacity;

  opacity: 0;
  transform: translateY(10%);
}

.session-control-enter-to,
.session-control-leave-from {
  will-change: transform, opacity;

  opacity: 1;
  transform: translateY(0);
}