.tooltip {
  position: absolute;

  border-radius: em(8, $fz);
  background-color: var(--phantom);
  box-shadow: em(0 4 20, $fz) var(--shadow);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  
  &::before {
    position: absolute;
    width: em(14, $fz);
    height: em(14, $fz);

    content: '';

    border-radius: em(4, $fz);
    background-color: var(--phantom);
  }

  &--is-warning {
    .tooltip__icon {
      color: var(--alert_light);
    }
  }

  &--is-bottom {
    top: calc(100% + #{em(11, $fz)});
    left: calc(#{em(-19, $fz)});

    &::before {
      top: em(-5, $fz);
      left: em(20, $fz);
      transform: rotate(45deg);
    }

    &.toggle-tooltip-enter-from,
    &.toggle-tooltip-leave-to {
      opacity: 0;
      transform: translateY(20%);
    }

    &.toggle-tooltip-enter-to,
    &.toggle-tooltip-leave-from {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--is-top {
    bottom: calc(100% + #{em(11, $fz)});
    left: calc(#{em(-19, $fz)});

    &::before {
      bottom: em(-5, $fz);
      left: em(20, $fz);
      transform: rotate(45deg);
    }

    &.toggle-tooltip-enter-from,
    &.toggle-tooltip-leave-to {
      opacity: 0;
      transform: translateY(-20%);
    }

    &.toggle-tooltip-enter-to,
    &.toggle-tooltip-leave-from {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--is-left {
    top: 50%;
    right: calc(100% + #{em(11, $fz)});
    transform: translateY(-50%);

    &::before {
      top: 50%;
      right: 0;
      transform: rotate(45deg) translateY(-50%);
    }

    &.toggle-tooltip-enter-from,
    &.toggle-tooltip-leave-to {
      opacity: 0;
      transform: translateY(-50%) translateX(-10%);
    }

    &.toggle-tooltip-enter-to,
    &.toggle-tooltip-leave-from {
      opacity: 1;
      transform: translateY(-50%) translateX(0);
    }
  }

  &--is-right {
    top: 50%;
    left: calc(100% + #{em(11, $fz)});
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: em(-11, $fz);
      transform: rotate(45deg) translateY(-50%);
    }

    &.toggle-tooltip-enter-from,
    &.toggle-tooltip-leave-to {
      opacity: 0;
      transform: translateY(-50%) translateX(10%);
    }

    &.toggle-tooltip-enter-to,
    &.toggle-tooltip-leave-from {
      opacity: 1;
      transform: translateY(-50%) translateX(0);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding: em(16, $fz);
    gap: em(8, $fz);
  }

  &__label {
    $fz: 14;
    color: var(--system);
    font-size: em($fz, $fz_base);
    line-height: em(22, $fz);
    white-space: nowrap;
  }
}