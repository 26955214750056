.coupon-print {
  max-width: em(380, $fz);

  padding: em(10, $fz);
  font-family: $font_Roboto;
  font-weight: 500;
  
  &__header {
    margin-bottom: em(20, $fz);

    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__logo {
    max-width: 100%;
    max-height: em(50, $fz);
  }
  
  &__sections {
    display: grid;
    grid-row-gap: em(8, $fz);
  }
  
  &__section {
    border: 2px solid var(--black);
    
    &--base-info {
      display: grid;
      grid-row-gap: em(3, $fz);
      padding: em(5, $fz);
    }
  }
  
  &__row {
    display: flex;
    justify-content: space-between;
    column-gap: em(5, $fz);
    
    &:not(:last-child) {
      border-bottom: 1px solid var(--black);
    }
  }
  
  &__cell {
    text-transform: uppercase;
  }
  
  &__heading {
    display: block;
    padding-top: em(5, $fz);
    padding-bottom: em(5, $fz);
    border-bottom: 1px solid var(--black);
    text-align: center;
    text-transform: uppercase;
  }
}

.coupon-print-events {
  padding: em(5, $fz);
}

.coupon-print-event {
  &__row {
    display: flex;
    justify-content: space-between;
    column-gap: em(5, $fz);
  }
  
  &__cell {
    display: flex;
    column-gap: em(5, $fz);
  }
  
  &__info {
    $fz: 13;
    font-size: em($fz, $fz_base);
  }
}

.coupon-print-stake {
  display: flex;
  column-gap: em(5, $fz);
  padding: em(5, $fz);
  
  &__title,
  &__amount {
    $fz: 21;
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 700;
  }
  
  &__dots {
    flex-grow: 1;
    background-image: radial-gradient(circle, black 3px, white 4px);
    background-position: 0 center;
    background-repeat: repeat-x;
    background-size: 11px 11px;
  }
}

.coupon-print-total {
  &__row {
    display: flex;
    justify-content: space-between;
    column-gap: em(5, $fz);
    padding: em(3 5, $fz);
    
    &:not(:last-child) {
      border-bottom: 1px solid var(--black);
    }
  }
  
  &__cell {
    text-transform: uppercase;
  }
}

.coupon-print-total-max {
  &__heading,
  &__value {
    flex-grow: 1;

    display: block;
    text-align: center;
    text-transform: uppercase;
  }
  
  &__value {
    $fz: 22;
    font-size: em($fz, $fz_base);
    font-weight: 700;
  }
}

.coupon-print-sub {
  display: grid;
  grid-row-gap: em(5, $fz);
  padding: em(5, $fz);
  
  &__row {
    border-bottom: 2px solid var(--black);
  }
  
  &__cell {
    $fz: 13;
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 700;
  }
  
  &__info {
    padding: em(15 20, $fz);
    text-align: center;
  }
  
  &__text {
    $fz: 13;
    font-size: em($fz, $fz_base);
  }
}

.coupon-print-barcode {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: em(15 30, $fz);
  
  &__code {
    max-width: 100%;
    max-height: 50px;
  }
}