.switcher {
  display: inline-flex;
  align-items: center;

  &__label {
    margin-inline-start: em(8, $fz);
    color: var(--brand_40);
    transition: color 0.2s ease-in-out;
  }
  
  &__slider {
    width: em(40, $fz);
    height: em(24, $fz);

    border-radius: em(12, $fz);
  }

  &--is-toggled {
    .switcher-slider {
      background-color: var(--brand);

      &__indicator {
        transform: translateX(100%);
      }
    }

    .switcher__label {
      color: var(--brand);
    }
  }
}

.switcher-slider {
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: em(5, $fz);
  background-color: var(--brand_40);
  transition: background-color 0.2s ease-in-out;

  &__indicator {
    width: em(14, $fz);
    height: em(14, $fz);

    border-radius: 50%;
    background-color: var(--phantom);
    transition: transform 0.2s ease-in-out;
  }
}