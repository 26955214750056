@mixin box($value, $radius: false) {
  width: $value;
  height: $value;

  @if  $radius != false {
    border-radius: $radius;
  }
}
@mixin flex($value: center) {
  display: flex;

  align-items: center;
  justify-content: $value;
}
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;

  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
@mixin text-points {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin focus($offset: -1px) {
  outline: 1px solid $focus;
  outline-offset: $offset;
}
@mixin image($size: contain) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size;
}
@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin old-edge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}

// Стили только для экранов с тачем
@mixin touchscreen {
  @media (hover: none) {
    @content;
  }
}
@mixin media-tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}
