.scrollbar {
  overflow: auto;
  scrollbar-color: hsla(0deg 0% 24% / 0.5);
  scrollbar-arrow-color: hsl(0deg 0% 62%);
  scrollbar-face-color: hsl(0deg 0% 62%);
  scrollbar-track-color: transparent;

  &--vertical {
    overflow-x: hidden;
  }

  &--horizontal {
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    width: em(4, $fz);
    height: em(4, $fz);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: em(4, $fz);
    background-color: var(--normal_50);
    transition: background-color .2s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    border-radius: em(4, $fz);
    background-color: var(--brand_12);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--normal);
    }
  }

  &--sidebar {
    padding-inline-end: 1px;

    overflow-x: hidden;


    &::-webkit-scrollbar {
      width: em(4, $fz);
    }

    &::-webkit-scrollbar-thumb {
      width: 100%;

      border-radius: 4px;
      background-color: hsla(208deg 100% 86% / 0.3);
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }
  }
}