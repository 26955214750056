.workspace {
  &__menu {
    flex: 0 0 em(84, $fz);
    max-width: em(84, $fz);
    
    & + .workspace__inner {
      @include media-tablet {
        margin-inline-start: em(20, $fz);
      }
      
      margin-inline-start: em(28, $fz);
    }
  }
}

.workspace-menu {
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    
    & + & {
      margin-top: em(30, $fz);
    }
  }
  
  &__link {
    flex-grow: 1;
  }
}

.workspace-menu-link {
  cursor: pointer;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--brand_40);
  
  &--is-disabled {
    pointer-events: none;
  }
  
  &--is-active,
  &:hover {
    color: var(--brand);
  }
  
  &:not(&--is-disabled) {
    &:hover {
      color: var(--brand);
    }
  }
  
  &__label {
    $fz: 13;
    margin-top: em(11, $fz);
    text-align: center;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    transition: color 0.2s ease-in-out;
  }
}

.workspace-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: em(8, $fz);
  color: var(--brand_40);
  transition: background-color 0.2s ease-in-out;
  
  &:hover,
  &:active {
    color: var(--brand);
  }
  
  &:active {
    background-color: var(--normal_selected);
  }
}