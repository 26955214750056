.input {
  position: relative;

  display: flex;
  flex-direction: column;
  border-radius: em(4, $fz);

  &__container {
    flex: auto;

    display: flex;
    outline: 2px solid transparent;
    outline-offset: -2px;
    border-width: 1px;
    border-style: solid;
    border-radius: inherit;
    border-color: transparent;
    transition: outline-color 0.12s ease-in-out, background-color .12s ease-in-out, border-color .12s ease-in-out;
    gap: em(12, $fz);
  }
  
  &:not(.input-select) {
    .input__container {
      padding: em(0 12, $fz);
    }
  }

  &__field {
    flex: auto 1 1;
    min-width: 0;

    display: flex;
    padding: em(12 0, $fz);
    //border-radius: inherit;
    color: var(--system);
    transition: background-color 0.12s ease-in-out;

    &::placeholder {
      opacity: 1;
      color: var(--brand_40);
      transition: opacity 0.12s ease-in-out;
    }
    
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--system);
    }
  }

  &__message {
    margin-top: em(4, $fz);

    &--error {
      .input-message__text {
        color: var(--alert);
      }
    }
  }

  &__icon,
  &__button,
  &__currency {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--brand_40);
  }
  
  &__button {
    @media (hover:hover) {
      &:hover {
        color: var(--btn_normal_hover);
      }
    }
    
    &:active {
      color: var(--btn_normal_pressed);
    }
  }
  
  &:not(&--is-locked) {
    .input {
      &__container {
        @media (hover: hover) {
          &:hover {
            outline: 2px solid var(--normal);
            
            .multiselect__caret {
              color: var(--normal);
            }
          }
        }
      }
    }
    
    &.input--is-focused {
      .input__container {
        outline: 2px solid var(--normal);
      }
    }
  }

  &--size-s {
    .input {
      &__container {
        height: em(32, $fz);
      }
    }
  }

  &--size-m {
    .input {
      &__container {
        height: em(40, $fz);
      }

      &__field {
        $fz: 14;
        font-size: em($fz, $fz_base);
      }
    }
  }

  &--size-l {
    .input {
      &__container {
        height: em(52, $fz);
      }

      &__field {
        $fz: 16;
        font-size: em($fz, $fz_base);
      }
    }
  }

  &--has-border {
    .input {
      &__container {
        border-color: var(--border);
        background-color: var(--phantom);
        
        .input__field:-webkit-autofill {
          box-shadow: 0 0 0 100px var(--phantom) inset;
        }
        
        .input__field:-internal-autofill-selected {
          background-color: -internal-light-dark(var(--divider), rgba(70, 90, 126, 0.4)) !important;
        }
      }
      
      &:not(.input--is-locked) {
        .input {
          &--is-focused,
          &__container:hover {
            border-color: transparent;
          }
        }
      }
    }
  }

  &--is-locked {
    cursor: not-allowed;

    .input {
      &__container {
        background-color: var(--divider);
      }
      
      &__field {
        //background-color: var(--divider);
        pointer-events: none;

        &,
        &::placeholder {
          color: var(--system_additional);
        }
        
        &:-webkit-autofill {
          box-shadow: 0 0 100px var(--divider) inset;
        }
      }
    }
  }

  &--no-border {
    .input {
      &__container {
        border-color: var(--divider);
        background-color: var(--divider);
        
        .input__field:-webkit-autofill {
          box-shadow: 0 0 100px var(--divider) inset;
        }
        
        .input__field:-internal-autofill-selected {
          background-color: -internal-light-dark(var(--divider), rgba(70, 90, 126, 0.4)) !important;
        }
      }
    }

    &.input--is-focused,
    &.input--is-hovered {
      .input__container {
        background-color: var(--phantom);
        
        .input__field:-webkit-autofill {
          box-shadow: 0 0 100px var(--phantom) inset;
        }
        
        .input__field:-internal-autofill-selected {
          background-color: -internal-light-dark(var(--phantom), rgba(70, 90, 126, 0.4)) !important;
        }
      }
    }
  }

  &--has-error {
    .input {
      &__container {
        border-color: var(--alert_light);
      }
    }
  }
  
  &--is-label-uppercase {
    .input__field {
      text-transform: uppercase;
    }
  }
}

.input-message {
  &__text {
    $fz: 12;
    color: var(--system_additional);
    font-size: em($fz, $fz_base);
    line-height: em(18, $fz);
  }
}

.input-currency {
  &__label {
    $fz: 20;
    font-size: em($fz, $fz_base);
  }
}