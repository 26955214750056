@keyframes OpacityFlicker {
  from { opacity: 0.4; }
}

.main {
  &__workplace {
    display: flex;
  }
}
//noinspection CssInvalidFunction
.workplace {
  filter: drop-shadow(em(0 4 20, $fz) var(--shadow));

  min-height: em(556, $fz);
}

.workplace-main {
  @include media-tablet {
    width: em(344, $fz);
  }
  
  position: relative;
  width: em(512, $fz);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: em(20, $fz);
  border-radius: em(4, $fz);
  background-color: var(--phantom);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    padding-top: inherit;
    padding-inline-end: inherit;
    text-align: right;
  }

  &__version {
    color: var(--brand_40);
    font-size: em(14, $fz);
    font-weight: 500;
  }

  &__inner {
    @include media-tablet {
      min-width: em(280, $fz);
    }
    
    flex: auto 0 1;
    min-width: em(312, $fz);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    color: var(--brand_40);
  }

  &__icon-flicker {
    animation: OpacityFlicker 0.4s infinite alternate;
  }

  &__title {
    $fz: 28;

    @include media-tablet {
      $fz: 24;
      font-size: em($fz, $fz_base);
    }
    margin-top: em(30, $fz);
    color: var(--brand);
    text-align: center;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(30, $fz);
  }

  &__description {
    max-width: em(265, $fz);
    margin-top: em(20, $fz);

    display: grid;
    grid-gap: em(12, $fz);
    text-align: center;
  }

  &__text {
    $fz: 14;
    color: var(--system_additional);
    font-size: em($fz, $fz_base);
    line-height: em(22, $fz);
  }

  &__form {
    width: 100%;
    max-width: em(312, $fz);
    margin-top: em(28, $fz);
  }
  
  &__buttons {
    width: 100%;
    margin-top: em(28, $fz);
    
    display: flex;
    align-items: center;
    column-gap: em(16, $fz);
  }
  
  &__button {
    &:not(&--small) {
      flex: 1 1 50%;
    }
    
    &--small {
      flex: 0 0 20%;
    }
  }
}
//noinspection CssInvalidFunction
.workplace-info {
  width: em(320, $fz);

  display: flex;
  flex-direction: column;
  padding: em(40 32, $fz);
  border-radius: em(3 0 0 3, $fz);
  background-color: var(--header_light);

  & + .workplace-main {
    border-radius: em(0 3 3 0, $fz);
  }

  &__title {
    $fz: 28;
    
    @include media-tablet {
      $fz: 24;
      font-size: em($fz, $fz_base);
    }
    color: var(--header_text);
    font-size: em($fz, $fz_base);
    font-weight: 500;
  }

  &__list {
    @include media-tablet {
      margin-top: em(32, $fz);
      margin-bottom: em(32, $fz);
    }
    margin-top: em(30, $fz);
    margin-bottom: em(35, $fz);
  }

  &__note {
    $fz: 12;
    color: var(--header_text_64);
    font-size: em($fz, $fz_base);
    line-height: em(18, $fz);
  }

  &__date {
    margin-top: auto;

    display: flex;
    flex-direction: column;
  }

  &__time {
    $fz: 20;
    color: var(--header_text);
    font-family: $font_Roboto_Mono;
    font-size: em($fz, $fz_base);
    font-weight: 700;
  }

  &__day {
    $fz: 14;
    margin-top: em(8, $fz);
    color: var(--header_text_64);
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 500;
  }
}

.workplace-info-list {
  @include media-tablet {
    gap: em(32, $fz);
  }
  display: grid;
  gap: em(35, $fz);
  
  &__item {
    display: flex;
    align-items: center;
    color: var(--header_text_64);
    gap: em(17, $fz);
  }

  &__icon {
    max-width: em(34, $fz);
    max-height: em(34, $fz);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__svg {
    max-width: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--header_text);
    font-weight: 500;
    line-height: em(28, $fz);
  }

  &__subtitle {
    $fz: 14;
    font-size: em($fz, $fz_base);
    line-height: em(18, $fz);
  }
}

.workplace-main-form {
  display: flex;
  flex-direction: column;
  gap: em(15, $fz);
}
