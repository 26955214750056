.balance-limit {
  position: relative;
  
  border-radius: em(4, $fz);
  
  &__scale {
    position: absolute;
    left: 0;
    height: 100%;
    
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  
  &--is-full {
    .balance-limit__scale {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
  
  &--is-normal {
    .balance-limit__scale {
      background-color: var(--success_light);
    }
  }
  
  &--is-caution {
    .balance-limit__scale {
      background-color: var(--caution_light);
    }
  }
  
  &--is-alert {
    .balance-limit__scale {
      background-color: var(--alert_light);
    }
  }
}