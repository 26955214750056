.ui-button {
  max-width: 100%;

  display: inline-flex;
  flex-direction: column;
  align-items: center;

  &__description {
    $fz: 12;
    max-width: 100%;
    margin-top: em(4, $fz);
    color: var(--unactive);
    text-align: center;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
  }
}

.button {
  width: 100%;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &__container {
    flex: auto 0 1;
    min-width: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    gap: em(8, $fz);
  }

  &__label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  //стиль кнопки
  &--style-default {
    border-radius: em(4, $fz);
  }
  
  &--style-outlined {
    border-style: solid;
    border-radius: em(4, $fz);
  }
  
  &--border-normal {
    border-width: 2px;
  }
  
  &--border-thin {
    border-width: 1px;
  }

  //размер кнопки для default и outlined
  &--size-s:not(.button--icon-only) {
    height: em(32, $fz);

    padding: em(5 14, $fz);
    
    .button__label {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }
  }

  &--size-m:not(.button--icon-only) {
    height: em(40, $fz);

    padding: em(12 14, $fz);
    
    .button__label {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }
  }

  &--size-l:not(.button--icon-only) {
    height: em(52, $fz);

    padding: em(12 14, $fz);
    
    .button__label {
      $fz: 16;
      font-size: em($fz, $fz_base);
    }
  }

  //Темы для rounded
  &--theme-outlined-white {
    border-color: var(--phantom_40);
    background-color: var(--phantom);
    color: var(--phantom);
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          border-color: var(--phantom_64);
        }
      }
      
      &:active {
        border-color: var(--phantom);
      }
    }
  }

  &--theme-outlined-primary {
    border-color: var(--btn_normal_default);
    background-color: var(--phantom);
    color: var(--btn_normal_default);

    .button {
      &__icon.icon--preloader {
        color: var(--normal);
      }
    }
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--normal_hover);
        }
      }
      
      &:active {
        background-color: var(--normal_selected);
      }
    }
  }

  //Темы для default и rounded
  &--theme-primary {
    background-color: var(--btn_normal_default);
    color: var(--phantom);

    .button {
      &__icon.icon--preloader {
        color: var(--normal);
      }
    }
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--btn_normal_hover);
        }
      }
      
      &:active {
        background-color: var(--btn_normal_pressed);
      }
    }
  }

  &--theme-green {
    background-color: var(--btn_success_default);
    color: var(--phantom);
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--btn_success_hover);
        }
      }
      
      &:active {
        border-color: var(--btn_success_pressed);
        background-color: var(--btn_success_pressed);
      }
    }

    &.button--is-loading,
    &.button--is-success {
      border-color: var(--btn_success_pressed);
      background-color: var(--backlight_success);
      color: var(--success);
    }
  }

  &--theme-orange {
    background-color: var(--btn_alert_default);
    color: var(--phantom);
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--btn_alert_hover);
        }
      }
      
      &:active {
        border-color: var(--btn_alert_pressed);
        background-color: var(--btn_alert_pressed);
      }
    }

    &.button--is-loading,
    &.button--is-success {
      border-color: var(--btn_alert_pressed);
      background-color: var(--backlight_alert);
      color: var(--alert);
    }
  }

  &--theme-blue {
    background-color: var(--normal_hover);
    color: var(--brand_64);
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--normal_selected);
          
          .button__icon {
            color: var(--brand_light);
          }
        }
      }
    }
  }

  &--theme-gray {
    background-color: var(--divider);
    color: var(--brand_40);
    
    &:not(.button--is-disabled) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--normal_hover);
          
          .button__icon {
            color: var(--system_additional);
          }
        }
      }
    }
  }

  //Опциональные модификаторы для состояний
  &--is-disabled {
    cursor: no-drop;

    border-color: var(--divider);
    background-color: var(--divider);
    color: var(--system_additional);
  }

  &--is-loading {
    background-color: var(--backlight_normal);
  }
  
  &--icon-only {
    &.button--size-s {
      min-width: em(32, $fz);
      height: em(32, $fz);
    }
    
    &.button--size-m {
      min-width: em(40, $fz);
      height: em(40, $fz);
    }
    
    &.button--size-l {
      min-width: em(52, $fz);
      height: em(52, $fz);
    }
  }

  &--theme-transparent {
    background-color: transparent;
    color: var(--brand_40);
  }
  
  &--is-label-uppercase {
    .button__label {
      text-transform: uppercase;
    }
  }
}
