@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.icon {
  flex: 0 0 auto;

  display: inline-flex;
  transition: color .2s ease-in-out;

  &--preloader {
    .icon__svg {
      animation: preloader 2s infinite ease-in-out;
    }
  }

  &--size-xxs {
    width: em(16, $fz);
    height: em(16, $fz);
  }

  &--size-xs {
    width: em(20, $fz);
    height: em(20, $fz);
  }

  &--size-s {
    width: em(24, $fz);
    height: em(24, $fz);
  }
  
  &--size-sm {
    width: em(28, $fz);
    height: em(28, $fz);
  }

  &--size-m {
    width: em(32, $fz);
    height: em(32, $fz);
  }
  
  &--size-ml {
    width: em(36, $fz);
    height: em(36, $fz);
  }

  &--size-l {
    width: em(40, $fz);
    height: em(40, $fz);
  }

  &--size-xl {
    width: em(64, $fz);
    height: em(64, $fz);
  }
  
  &--size-custom-80 {
    width: em(80, $fz);
    height: em(80, $fz);
  }
  
  &--size-custom-95 {
    width: em(95, $fz);
    height: em(95, $fz);
  }
  
  &--size-custom-104 {
    width: em(104, $fz);
    height: em(104, $fz);
  }
  
  &--size-custom-175 {
    width: em(175, $fz);
    height: em(175, $fz);
  }
  
  &--size-custom-280 {
    width: em(280, $fz);
    height: em(280, $fz);
  }

  &--has-tooltip {
    position: relative;
  }
  
  /* rtl:begin:ignore */
  &--is-mirrored {
    [dir="rtl"] & {
      transform: scaleX(-1);
    }
  }
  
  /* rtl:end:ignore */
}
