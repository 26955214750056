.ui-rounded-button {
  max-width: 100%;
  
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  
  &__description {
    $fz: 12;
    
    max-width: 100%;
    margin-top: em(4, $fz);

    padding: em(0 10, $fz);
    color: var(--unactive);
    text-align: center;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
  }
  
  &__border {
    position: absolute;
    z-index: -1;
    fill: transparent;

    width: auto;
    height: auto;
  }
  
  &__circle {
    &--is-under {
      stroke: var(--divider);
    }
    
    &--is-over {
      transform: rotate(-90deg) translateX(-100%) #{"/*! rtl:ignore */;"};
    }
  }
}

.rounded-button {
  position: relative;
  z-index: 1;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  padding: em(3, $fz);
  border-radius: 50%;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  
  &__container {
    flex: auto 0 1;
    min-width: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    gap: em(8, $fz);
  }
  
  &__label {
    $fz: 12;
    display: block;
    overflow: initial;
    text-align: center;
    text-transform: uppercase;
    text-overflow: initial;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);
    letter-spacing: .03em;
    white-space: normal;
  }
  
  //размер кнопки для rounded
  &--size-m {
    width: em(72, $fz);
    height: em(72, $fz);
    margin: em(8, $fz);
    
    .ui-rounded-button {
      &__border {
        top: em(-8, $fz);
        left: em(-8, $fz);
      }
      
      &__circle {
        stroke-width: em(4, $fz);
        stroke-dasharray: em(264 264, $fz);
      }
    }
  }
  
  &--size-l {
    width: em(81, $fz);
    height: em(81, $fz);
    margin: em(10, $fz);
    
    .ui-rounded-button {
      &__border {
        top: em(-10, $fz);
        left: em(-10, $fz);
      }
      
      &__circle {
        stroke-width: em(5, $fz);
        stroke-dasharray: em(302 302, $fz);
      }
    }
  }
  
  &--theme-green {
    background-color: var(--btn_success_default);
    
    .ui-rounded-button__circle--is-over {
      stroke: var(--btn_success_pressed);
    }
    
    .rounded-button {
      &__label,
      &__icon {
        color: var(--phantom);
      }
    }
    
    &:hover {
      background-color: var(--btn_success_hover);
    }
    
    &:active {
      border-color: var(--btn_success_pressed);
      background-color: var(--btn_success_pressed);
    }
    
    &.rounded-button--is-loading,
    &.rounded-button--is-success {
      border-color: var(--btn_success_pressed);
      background-color: var(--backlight_success);
      
      .rounded-button {
        &__label {
          color: var(--success);
        }
        
        &__icon.icon--preloader {
          color: var(--success);
        }
      }
    }
  }
  
  &--theme-orange {
    background-color: var(--btn_alert_default);
  
    .ui-rounded-button__circle--is-over {
      stroke: var(--btn_alert_pressed);
    }
    
    &:hover {
      background-color: var(--btn_alert_hover);
    }
    
    &:active {
      border-color: var(--btn_alert_pressed);
      background-color: var(--btn_alert_pressed);
    }
    
    &.rounded-button--is-loading,
    &.rounded-button--is-success {
      border-color: var(--btn_alert_pressed);
      background-color: var(--backlight_alert);
      
      .rounded-button {
        &__label {
          color: var(--alert);
        }
        
        &__icon.icon--preloader {
          color: var(--alert);
        }
      }
    }
    
    .rounded-button__label {
      color: var(--phantom);
    }
  }
  
  //Опциональные модификаторы для состояний
  &--is-disabled {
    cursor: no-drop;
    background-color: var(--divider);
    pointer-events: none;
    
    .button__label {
      color: #808185;
    }
  }
  
  &--is-loading {
    background-color: var(--backlight_normal);
  }
  
  &--is-label-ttu {
    .button__label {
      text-transform: uppercase;
    }
  }
}
