.workspace-side-menu {
  position: absolute;
  top: var(--header-height_desktop);
  bottom: 0;
  left: 0;
  z-index: 2;
  width: em(280, $fz);

  display: flex;
  flex-direction: column;
  background-color: var(--phantom);
  box-shadow: em(0 4 20, $fz) var(--shadow);
  transition: transform .2s ease-in-out;
  
  &__button {
    margin: em(auto 24 12, $fz);
  }
}

.workspace-side-menu-link  {
  width: 100%;

  display: flex;
  align-items: center;
  padding: em(12 24, $fz);
  transition: background-color .2s ease-in-out;
  gap: em(12, $fz);
  
  &__icon {
    color: var(--brand);
  }
  
  &__label {
    $fz: 12;
    color: var(--brand);
    font-size: em($fz, $fz_base);
    font-weight: 500;
  }
  
  &--is-active {
    cursor: auto;
    background-color: var(--normal_selected);
  }
  
  &:not(&--is-active) {
    &:hover {
      background-color: var(--normal_hover);
    }
  }
}

.side-menu-enter-from,
.side-menu-leave-to {
  transform: translateX(-100%);
}

.side-menu-enter-to,
.side-menu-leave-from {
  transform: translateX(0);
}