.user {
  position: relative;
  width: em(227, $fz);

  cursor: pointer;

  display: flex;
  align-items: center;
  padding: em(0 16, $fz);
  transition: background-color 0.2s ease-in-out;

  @media screen and (max-width: $macBook_pro_13inch) {
    width: auto;
  }

  &__name {
    $fz: 14;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    font-size: em($fz, $fz_base);
    transition: color 0.2s ease-in-out;
    
    @media screen and (max-width: $macBook_pro_13inch) {
      display: none;
    }
  }

  &__current {
    width: 100%;

    display: flex;
    align-items: center;
    color: var(--header_text_64);
    gap: em(10, $fz);
  }

  &__arrow {
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
    
    @media screen and (max-width: $macBook_pro_13inch) {
      display: none;
    }
  }
  
  &__theme {
    border-top: 1px solid var(--header_text_20);
  }

  &:hover {
    background-color: var(--header_light);

    .user {
      &__current {
        color: var(--header_text);
      }

      &__arrow {
        transform: rotate(180deg);
      }
    }

    .user-options {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }
}

.user-options {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  background-color: var(--header_light);
  transform: translateY(-20%);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
  pointer-events: none;
  
  @media screen and (max-width: $macBook_pro_13inch) {
    width: max-content;
  }
}

.user-option {
  width: 100%;

  display: flex;
  align-items: center;
  padding: em(14 20, $fz);
  color: var(--header_text_64);
  transition: background-color 0.2s ease-in-out;
  gap: em(12, $fz);

  &__label {
    $fz: 14;
    font-size: em($fz, $fz_base);
    line-height: em(20, $fz);
    transition: color 0.2s ease-in-out;
  }

  &:not(&--is-locked) {
    &:hover,
    &:active {
      color: var(--header_text);
    }

    &:active {
      background-color: var(--header);
    }
  }

  &--is-locked {
    color: var(--header_text_40);

    .user-option {
      &__icon,
      &__label {
        opacity: 0.24;
      }
    }
  }
}

.user-current {
  display: flex;
  align-items: center;
  padding: em(16 16 16 16, $fz);
  border-bottom: 1px solid var(--phantom_64);
  color: var(--header_text_64);
  gap: em(8, $fz);
  
  @media screen and (min-width: $macBook_pro_13inch) {
    display: none;
  }
}

.user-theme {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: em(14 20, $fz);
  gap: em(12, $fz);
  
  &__label {
    $fz: 14;
    flex-grow: 1;
    color: var(--header_text_64);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(20, $fz);
  }
  
  &__switcher {
    outline: 1px solid var(--header_text_20);
    border-radius: em(12, $fz);
    background-color: var(--header_text);
  }
}