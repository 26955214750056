.overlay {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  
  &--theme-light {
    background-color: var(--phantom_40);
  }
  
  &__modal {
    max-width: em(640, $fz);
    min-height: em(260, $fz);
    flex-grow: 1;
  }
  
  &.modal-overlay-enter-from,
  &.modal-overlay-leave-to {
    opacity: 0;
    
    .modal {
      opacity: 0;
      transform: translateY(-20%);
    }
  }
  
  &.modal-overlay-enter-to,
  &.modal-overlay-leave-from {
    opacity: 1;
    
    .modal {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &--is-epos-mobile {
    position: absolute;

    align-items: flex-start;
    
    .overlay__modal {
      min-height: 100%;
    }
    
    .modal {
      padding-top: em(44, $fz);
      
      &__header {
        min-height: em(36, $fz);
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-inline-end: em(8, $fz);
      }
      
      &__content {
        display: grid;
        row-gap: em(24, $fz);
        
        column-gap: em(44, $fz);
        padding: em(0 20 36, $fz);
      }
      
      &__button {
        width: 100%;
      }
    }
  }
  
  &--is-epos-desktop,
  &--is-epos-tablet {
    position: fixed;

    align-items: center;
    
    .modal {
      padding: em(44, $fz);
      border-radius: em(8, $fz);
      
      &__content {
        display: flex;
        align-items: flex-start;
        column-gap: em(44, $fz);
      }
    }
  }
}

.modal {
  position: relative;
  
  background-color: var(--phantom);
  box-shadow: em(0 4 20, $fz) var(--shadow);
  transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
  
  &__close {
    position: absolute;
    top: em(8, $fz);
    right: em(8, $fz);
  }
  
  &__icon {
    &--is-alert {
      color: var(--alert);
    }
    
    &--is-success {
      color: var(--success);
    }
  }
  
  &__info {
    flex: 1;
  }
  
  &__title {
    $fz: 26;
    margin-bottom: em(12, $fz);
    color: var(--brand_light);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(34, $fz);
  }
  
  &__description {
    color: var(--brand);
    line-height: em(26, $fz);
    
    & ~ & {
      margin-top: em(12, $fz);
    }
  }
  
  &__divider {
    height: 1px;
    margin: em(12 0, $fz);
    background-color: var(--divider);
  }
  
  &__buttons {
    margin-top: em(32, $fz);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: em(12, $fz);
  }
  
  &__button {
    min-width: em(180, $fz);
  }
}

.modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--brand_40);
  
  &__container {
    display: flex;
  }
  
  &:hover {
    color: var(--brand);
  }
}