.status {
  position: relative;
  
  &--is-normal {
    .status-button {
      color: var(--header_text_40);
    }
  }
  
  &--is-good {
    .status-button {
      color: var(--success_light);
    }
  }
  
  &--is-bad {
    .status-button {
      color: var(--alert_light);
    }
  }
  
  &--has-notifications {
    .status-button {
      color: var(--header_text);
      
      .notification {
        fill: var(--alert_light);
      }
    }
  }
}

.status-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-tooltip {
  position: absolute;
  top: calc(100% + #{em(10, $fz)});
  z-index: 3;
  width: em(385, $fz);

  padding: em(8 0, $fz);
  border-radius: em(4, $fz);
  background-color: var(--header_text);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  filter: drop-shadow(em(0 4 20, $fz) var(--shadow));

  &::before {
    position: absolute;
    top: em(-6, $fz);
    width: em(14, $fz);
    height: em(14, $fz);

    content: '';

    border-radius: em(4, $fz);
    background-color: var(--header_text);
    transform: rotate(45deg);
  }
  
  &--position-left {
    left: em(-10, $fz);
    
    &::before {
      left: em(20, $fz);
    }
  }
  
  &--position-right {
    right: em(-10, $fz);
  
    &::before {
      right: em(20, $fz);
    }
  }

  &__item {
    & ~ & {
      border-top: 1px solid var(--divider);
    }
  }

  &.header-status-tooltip-enter-from,
  &.header-status-tooltip-leave-to {
    opacity: 0;
    transform: translateY(5%);
  }

  &.header-status-tooltip-enter-to,
  &.header-status-tooltip-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
}

.status-notification {
  display: flex;
  align-items: flex-start;
  padding: em(8 16, $fz);
  gap: em(12, $fz);

  &__icon {
    &:not(&--is-alert) {
      color: var(--normal);
    }

    &--is-alert {
      color: var(--alert);
    }
  }

  &__content {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title,
  &__text {
    $fz: 12;
    color: var(--system);
    font-size: em($fz, $fz_base);
    line-height: em(18, $fz);
  }

  &__title {
    font-weight: 700;
  }

  &__text {
    $fz: 12;
    margin-top: em(4, $fz);
  }

  &__date {
    $fz: 11;
    margin-top: em(8, $fz);
    color: var(--unactive);
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(18, $fz);
  }
  
  &__button {
    min-width: em(138, $fz);
    margin-top: em(16, $fz);
  }
  
  &__list {
    margin-top: em(8, $fz);
  }
}

.status-notification-close {
  align-self: stretch;
  min-width: 10%;

  display: flex;
  justify-content: flex-end;
  color: var(--brand_40);

  &:hover {
    color: var(--brand);
  }
}

.status-notification-list {
  color: var(--system);
  list-style: decimal inside;
  
  &__item {
    $fz: 14;
    font-size: em($fz, $fz_base);
    line-height: em(22, $fz);
  }
}
