.v-popper {
  width: max-content;
  display: inline-flex;
  
  &--theme-dropdown {
    .v-popper {
      &__inner {
        border: 1px solid #ddd;
        border-radius: 6px;
        background: #fff;
        color: #000;
        box-shadow: 0 6px 30px #0000001a
      }
      
      &__arrow-inner {
        visibility: visible;
        
        border-color: #fff
      }
      
      &__arrow-outer {
        border-color: #ddd
      }
    }
  }
  
  &--theme-tooltip {
    .v-popper {
      &__inner {
        padding: em(16, $fz);
        border-radius: em(8, $fz);
        background: var(--phantom);
        color: var(--system);
        box-shadow: em(0 4 20 0, $fz) var(--shadow);
      }
      
      &__arrow-outer {
        border-radius: em(4, $fz);
        border-color: var(--phantom);
      }
    }
  }
  
  &__popper {
    top: 0;
    left: 0;
    z-index: 10000;
    
    &--hidden {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s
    }
    
    &--shown {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s
    }
    
    &--skip-transition,
    & > .v-popper__wrapper {
      transition: none !important
    }
    
    &--arrow-overflow {
      .v-popper__arrow-container {
        display: none
      }
    }
  }
  
  &__inner {
    position: relative;

    box-sizing: border-box;
    overflow-y: auto;
    
    & > div {
      $fz: 14;
      position: relative;
      z-index: 1;
      color: var(--system);
      font-size: em($fz, $fz_base);
    }
  }
  
  &__arrow-container {
    position: absolute;
    width: 14px;
    height: 10px
  }
  
  &__arrow-inner,
  &__arrow-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    border-style: solid
  }
  
  &__arrow-inner {
    visibility: hidden;

    border-width: 7px
  }
  
  &__arrow-outer {
    border-width: 6px
  }
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  left: -2px
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  left: -1px
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer {
  border-bottom-width: 0;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner {
  top: -2px
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-container {
  top: 0
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  border-top-width: 0;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  top: -4px
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  top: -6px
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  top: -2px
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  top: -1px
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  border-left-width: 0;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  left: -4px
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  left: -6px
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-container {
  right: -10px
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer {
  border-right-width: 0;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner {
  left: -2px
}
