
            @import '@/../styles/shared/helpers/_functions.scss';
            @import '@/../styles/shared/helpers/_mixins.scss';
            @import '@/../styles/shared/helpers/_fonts.scss';
            @import '@/../styles/shared/helpers/_global-variables.scss';
        
@import 'styles/shared/reset';
@import 'styles/shared/components/scrollbar';
@import 'styles/shared/components/v-tooltip';
@import 'styles/shared/helpers/transitions';

html {
  height: 100%;

  box-sizing: border-box;
  font-family: $font_Roboto;
  font-size: 16px;

  //@media (max-width: 1600px) {
  //  font-size: 15px;
  //}
  //@media (max-width: 1366px) {
  //  font-size: 14px;
  //}
  //@media(max-width: 1280px) {
  //  font-size: 13px;
  //}
  //@media(max-width: 1024px) {
  //  font-size: 12px;
  //}
  @media (min-width: 1921px) {
    font-size: 0.83333333333334vw;
  }
  
  &[lang="ar"] {
    font-family: $font_Cairo;
    line-height: 130%;
  }
  
  &[lang="bn"] {
    font-family: $font_HindSiliguri;
    line-height: 120%;
  }
}

body {
  min-width: em(1024, $fz);

  overflow-x: hidden;
}

.page {
  height: 100%;

  overflow: hidden;

  &__main {
    height: 100%;

    &--not-auth {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--header) url('../../../images/page-lines.png') no-repeat center/cover;
    }
  }
}

.coupon-container {
  display: none;
}

@media print {
  .main__workspace {
    display: none;
  }
  
  .coupon-container {
    display: block;
  }
}

// класс для запрета выделения текста
.no-select {
  user-select: none;
}

// класс для добавления кнопок управления для input[type="number"]
.is-need-spin-buttons {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: auto;
    
    margin: auto;
  }
  
  // Firefox
  &[type="number"] {
    appearance: auto;
  }
}

/*! rtl:begin:ignore */
[dir="rtl"] {
  .is-no-mirrored {
    justify-content: flex-end;
    direction: ltr;
    text-align: right;
  }
}

/*! rtl:end:ignore */

.fade-in-out-enter-from,
.fade-in-out-leave-to {
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.fade-in-out-enter-to,
.fade-in-out-leave-from {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}